import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "scrollContainer",
    "carouselItem",
    "indicator"
  ]

  initialize() {
    this.observer = new IntersectionObserver(this.onIntersectionObserved.bind(this), {
      root: this.scrollContainerTarget,
      threshold: 0.5
    })
    this.carouselItemTargets.forEach(carouselItem => {
      this.observer.observe(carouselItem)
    })
  }

  onIntersectionObserved(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.5) {
        const intersectingIndex = this.carouselItemTargets.indexOf(entry.target)
        this.indicatorTargets[intersectingIndex].classList.remove("bg-gray-300")
        this.indicatorTargets[intersectingIndex].classList.add("bg-mnb-secondary")
      }
      else {
        const intersectingIndex = this.carouselItemTargets.indexOf(entry.target)
        this.indicatorTargets[intersectingIndex].classList.remove("bg-mnb-secondary")
        this.indicatorTargets[intersectingIndex].classList.add("bg-gray-300")
      }
    })
  }

  scrollTo() {
    const carouselItemId = event.target.dataset.carouselItemId
    const carouselItemElement = document.getElementById(carouselItemId)
    const carouselItemCoordinates = carouselItemElement.getBoundingClientRect()
    this.scrollContainerTarget.scrollTo({ left: (carouselItemCoordinates.width*(carouselItemId - 1)), top: false, behavior: "smooth" })
  }
}
