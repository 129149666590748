import { Controller  } from 'stimulus'

export default class extends Controller {
  static targets = [
    "container",
  ]

  showNavbar() {
    if (window.pageYOffset > 0) {
      this.containerTarget.classList.add("shadow-lg");
    } else {
      this.containerTarget.classList.remove("shadow-lg")
    }
  }
}
