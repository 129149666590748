import { Controller } from "stimulus"
import { scrollIntoView } from "seamless-scroll-polyfill"

export default class extends Controller {
  static values = { selector: String  }

  scrollIntoView() {
    scrollIntoView(document.getElementById(this.selectorValue), { behavior: "smooth", block: "start"});
  }
}
