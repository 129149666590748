import { Controller  } from 'stimulus'

export default class extends Controller {
  static targets = [
    "socialLinks"
  ]

  connect() {
    this.addCopyrightNotice()
  }

  addCopyrightNotice() {
    let currentYear = new Date().getFullYear()
    let copyright = `<div class="mt-8 md:mt-0 md:order-1">
        <p class="text-base leading-6 text-center text-gray-400">
          &copy; ${ currentYear } MyNestBox Ltd. All rights reserved.
        </p>
      </div>`
    this.socialLinksTarget.insertAdjacentHTML('afterend', copyright)
  }
}
