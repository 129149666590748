import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "source",
    "container"
  ]

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()

    navigator.clipboard.writeText(this.sourceTarget.value)
      .then(() => { this.containerTarget.classList.remove("opacity-0") })
      .then(() => { setTimeout(() => this.containerTarget.classList.add("opacity-0"), 1000) })
      .catch((error) => { alert(`Copy failed! ${error}`) })
  }
}
