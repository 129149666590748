import { Controller  } from 'stimulus'

export default class extends Controller {
  static targets = [
    "container",
    "button"
  ]

  toggle() {
    this.containerTarget.classList.toggle("hidden")
    this.buttonTarget.classList.toggle("bg-mnb-primary-dark")
  }
}
