import { Controller  } from 'stimulus'

export default class extends Controller {
  static targets = [
    'showmoreButton',
    'showlessButton',
    'featuredContent',
    'allContent'
  ]

  static classes = [ "toggle" ]

  toggle() {
    this.showmoreButtonTarget.classList.toggle("hidden")
    this.showlessButtonTarget.classList.toggle("hidden")

    for (let featuredContent of this.featuredContentTargets) {
      featuredContent.classList.toggle("hidden")
    }

    for (let allContent of this.allContentTargets) {
      allContent.classList.toggle("hidden")
    }
  }
}
