import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "title",
    "subtitle",
  ]

  connect() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.has('title')) {
      this.titleTarget.innerHTML = urlParams.get('title')
    }
    if (urlParams.has('subtitle')) {
      this.subtitleTarget.innerHTML = urlParams.get('subtitle')
    }
  }
}
