import { Controller  } from 'stimulus'

export default class extends Controller {

  static targets = [
    "arrowContainer",
    "arrowIcon",
    "content"
  ]

  static values = { selector: String  }

  toggle(e) {
    e.preventDefault()
    this.arrowContainerTarget.classList.toggle("bg-mnb-orange")
    this.arrowIconTarget.classList.toggle("text-white")
    this.arrowIconTarget.classList.toggle("rotate-180")
    this.contentTarget.classList.toggle("max-h-0")
    this.contentTarget.classList.toggle("max-h-full")
  }
}
