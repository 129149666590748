import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card"];

  toggle() {
    if (!(this.cardTarget.classList.contains("hidden"))) {
      this.cardTarget.classList.add("hidden")
      return
    }
    let cards = document.querySelectorAll(".hovercard")
    cards.forEach(card => {
        if (!(card.classList.contains("hidden"))) {
          card.classList.add("hidden")
        }
      }
    )
    this.cardTarget.classList.remove("hidden")
  }
}
